
<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="cards">
          <form id="updateSalesInvoices" @submit="updateSalesInvoices">
            <CCardHeader>
              <CCol col="6" class="styleHeader">
                {{ $t("message.updateSalesInvoices") }}</CCol
              >
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6" v-if="customer.id">
                  <div class="required form-group">
                    <label>
                      {{ $t("message.customer") }}
                    </label>
                    <CButton
                      type="button"
                      @click="showModal = true"
                      class="copy add-btn"
                    >
                      +
                    </CButton>
                    <multiselect
                        id="customer"
                        v-model="customer"
                        :options="customers"
                        :searchable="true"
                        @select="selectCustomer"
                        :close-on-select="true"
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label="name"
                        track-by="id"
                        required="required"
                      >
                        <span slot="noOptions">{{
                          $t("message.emptyList")
                        }}</span>
                        <span slot="noResult"
                          >{{ $t("message.noResult") }}
                        </span>
                      </multiselect>
                  </div>
                </CCol>
                <CCol sm="6" v-if="customer.id">
                  <div class="form-group">
                    <label>
                      {{ $t("message.balance") }}
                    </label>
                    <br />
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      :placeholder="$t('message.balance')"
                      name="balance"
                      v-model="customer.balance"
                    />
                  </div>
                </CCol>
                        <CCol sm="6" v-if="saleInvoice.office_id">
                  <div class="required form-group">
                    <label>
                      {{ $t("message.office") }}
                    </label>
                     <multiselect
                        class='required'
                        v-model='selectedOffice'
                        id='job_id'
                        :options='offices'
                        :searchable='true'
                         @select="onChange"
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='name'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                  </div>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol sm="6">
                  <CInput
                    :label="$t('message.invoicePrice')"
                    id="invoice_price"
                    v-model="saleInvoice.price_paid"
                    class="required"
                    :placeholder="$t('message.invoicePrice')"
                    :invalidFeedback="errors.saleInvoice"
                    required
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle" />
                {{ $t("message.save") }}</CButton
              >
              &emsp;
              <CButton
                type="button"
                size="sm"
                color="btn btn-primary"
                @click="goBack"
              >
                <CIcon name="cil-ban" /> {{ $t("message.back") }}
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
     <Modal v-model="showModal">
      <NewCustomer />
    </Modal>
  </div>
</template>

<script>
import swal from 'sweetalert'
import NewCustomer from '@/components/NewCustomer.vue'
import $ from 'jquery'
export default {
  name: 'UpdateSalesInvoices',
  data: function () {
    return {
      showModal: false,
      saleInvoice: {},
      customer: {},
      customers: [],
      balance: '',
      offices: [],
      selectedOffice: '',
      errors: []
    }
  },
  components: {
    NewCustomer
  },
  created () {
    const current = this
    this.$http
      .get(`${this.$hostUrl}sale-invoices/${this.$route.params.id}/edit`)
      .then((response) => {
        current.saleInvoice = response.data.data.sale_invoice
        if (current.saleInvoice.customer) {
          current.customer = current.saleInvoice.customer
          current.customer.name = current.saleInvoice.customer.name + ' - ' + current.saleInvoice.customer.phone
          current.balance = current.saleInvoice.customer.balance
        } else if (current.saleInvoice.office_id) {
          this.selectedOffice = current.saleInvoice.office
        }
      })
    current.$http
      .get(`${this.$hostUrl}offices`)
      .then((response) => {
        this.offices = response.data.data
      })
    const customers = []
    this.$http.get(`${this.$hostUrl}customers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        customers.push({
          id: value.id,
          name: value.name + ' - ' + value.phone,
          phone: value.phone,
          balance: value.balance
        })
      })
      this.customers = customers
    })
  },
  methods: {
    selectCustomer (event) {
      const current = this
      current.name = event.name
      current.balance = event.balance
      console.log('event', event)
      current.customerPhone = event.phone
    },
    onChange ($event) {
      this.customer = {}
      this.customerPhone = ''
      this.balance = ''
    },
    removeOffice () {
      this.selectedOffice = ''
    },
    search () {
      console.log('this.customerPhone', this.customer.phone)
      const current = this
      this.customerPhone = current.customer.phone
      if (this.customerPhone.length === 10) {
        this.$http
          .get(`${this.$hostUrl}customers-search?phone=${this.customerPhone}`)
          .then((response) => {
            current.customer = response.data.data
          })
      }
    },
    async updateSalesInvoices (e) {
      e.preventDefault()
      if (this.customerPhone) {
        if (this.customerPhone.length !== 10) {
          swal(this.$t('message.errorCustomerPhone'))
          return
        }
      }
      const formData = new FormData()
      formData.append('price_paid', this.saleInvoice.price_paid)
      if (this.customer.id) {
        formData.append('customer', JSON.stringify(this.customer))
      } else if (this.selectedOffice.id) {
        formData.append('office_id', this.selectedOffice.id)
      } else {
        swal(this.$t('message.saleOrderNote'))
        return 0
      }
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(
        formData,
        `${this.$hostUrl}sale-invoices/${this.$route.params.id}`,
        '/payments'
      )
    },
    goBack () {
      this.areasOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/payments' })
    }
  }
}
</script>
<style>
.order {
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add {
  background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
</style>
